export enum FeaturesIconMode {
  Bed = 'bed',
  Shower = 'shower',
  Couch = 'couch',
  Window = 'window',
  Floor = 'floor',
  Wifi = 'wifi',
  Heat = 'heat',
  Coffee = 'coffee',
  Parking = 'parking',
}

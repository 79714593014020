import styled, { css } from 'styled-components';

import { FeaturesStyledProps } from './types';

import { FlexBox } from '../../styles/Grid/styles';
import { Typography } from '../../styles/Typography/styles';
import media from '../../../common/MediaQueries';

export const FeaturesList = styled(FlexBox)`
  flex-direction: column;

  @media ${media.phone} {
    flex-direction: row;
  }
`;

export const FeaturesListItem = styled.div`
  display: flex !important;
  align-items: center;
  width: 100%;
`;

export const FeaturesIcon = styled(FlexBox)`
  width: 4.5rem;

  svg {
    font-size: 5.375rem;
    color: ${({ theme }) => theme.colors.additional.dark};
  }
`;

export const FeaturesTitle = styled(Typography)`
  width: calc(100% - 4.5rem);
  max-width: 10.25rem;
  padding-left: 1rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const FeaturesListWrapper = styled.div<FeaturesStyledProps>`
  .slick-slide {
    margin-left: 2.25rem;
  }
  .slick-dots {
    margin-top: 2.5rem;
  }

  ${({ isAlt }) =>
    isAlt &&
    css`
      ${FeaturesIcon} {
        width: 1.5rem;
      }
      ${FeaturesIcon} svg {
        font-size: 1.5rem;
      }
      ${FeaturesTitle} {
        padding-left: 1.125rem;
        width: 100%;
      }
      @media ${media.phone} {
        display: flex;
        justify-content: center;
        .slider {
          display: flex;
          justify-content: center;
        }
        ${FeaturesListItem} {
          width: auto;
          margin-left: 1.5rem;
        }
      }
    `}
`;

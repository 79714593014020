import { graphql } from 'gatsby';
import React from 'react';

import Features from '../../Features';
import { HotelWithFeaturesListSectionProps } from './types';

const HotelWithFeaturesListSection: React.VFC<HotelWithFeaturesListSectionProps> = ({
  subtitle,
  title,
  content,
  mainFeatures,
  additionalFeatures,
}) => (
  <Features
    data={{
      subtitle: subtitle ?? '',
      title: title ?? '',
      content: content ?? undefined,
      mainFeatures: (mainFeatures ?? []).map((singleFeature) => {
        const { icon, title: featureTitle } = singleFeature ?? {};

        return {
          key: `${icon}-${featureTitle}` ?? '',
          title: featureTitle ?? '',
          iconMode: icon ?? '',
        };
      }),
      additionalFeatures: (additionalFeatures ?? []).map((singleFeature) => {
        const { icon, title: featureTitle } = singleFeature ?? {};

        return {
          key: `${icon}-${featureTitle}` ?? '',
          title: featureTitle ?? '',
          iconMode: icon ?? '',
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HotelWithFeaturesListSectionFragment on SanityHotelWithFeaturesListSection {
    _key
    _type
    subtitle
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    mainFeatures {
      title
      icon
    }
    additionalFeatures {
      title
      icon
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HotelWithFeaturesListSection;

import styled from 'styled-components';

import { Inner, FlexBox } from '../../styles/Grid/styles';
import { HeadingContent } from '../../molecules/Heading/styles';

export const Features = styled(Inner)``;

export const FeaturesHeading = styled.div`
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;

  ${HeadingContent} {
    color: ${({ theme }) => theme.colors.additional.accentGray2};
  }
`;

export const FeaturesMainItems = styled(FlexBox)`
  justify-content: center;
  margin-top: 2.5rem;
`;

export const FeaturesAdditionalItems = styled.div`
  margin-top: 2.5rem;
`;

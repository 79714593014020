import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { FeaturesListProps } from './types';
import { singleFeatureTemplate, settings } from './const';

const FeaturesList: React.FC<FeaturesListProps> = ({ items, isAlt }) => {
  return (
    <Styled.FeaturesListWrapper {...{ isAlt }}>
      {isAlt ? (
        <Slider {...settings}>{items.map((item) => singleFeatureTemplate(item, 'div'))}</Slider>
      ) : (
        <Styled.FeaturesList as="ul">
          {items.map((item) => singleFeatureTemplate(item, 'li'))}
        </Styled.FeaturesList>
      )}
    </Styled.FeaturesListWrapper>
  );
};

export default FeaturesList;

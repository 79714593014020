import React from 'react';

import * as Styled from './styles';
import { FeaturesProps } from './types';

import Heading from '../../molecules/Heading';
import FeaturesList from '../../molecules/FeaturesList';

export const Features: React.FC<FeaturesProps> = ({ data }) => {
  const { title, subtitle, content: contentRichText, mainFeatures, additionalFeatures } = data;

  return (
    <Styled.Features>
      <Styled.FeaturesHeading>
        <Heading {...{ title, subtitle, contentRichText }} />
      </Styled.FeaturesHeading>
      {mainFeatures && (
        <Styled.FeaturesMainItems>
          <FeaturesList items={mainFeatures} />
        </Styled.FeaturesMainItems>
      )}
      {additionalFeatures && (
        <Styled.FeaturesAdditionalItems>
          <FeaturesList items={additionalFeatures} isAlt />
        </Styled.FeaturesAdditionalItems>
      )}
    </Styled.Features>
  );
};
export default Features;

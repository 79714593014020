import React from 'react';
import { Settings as CarouselSettings } from 'react-slick';

import { FeaturesIconMode } from './enums';
import { FeaturesListItemProps } from './types';
import * as Styled from './styles';

import * as CarouselStyled from '../../atoms/Carousel/styles';
import {
  IllustrationKingSizeBed,
  IllustrationShower,
  IllustrationCouch,
  IconKanban,
  IconBarcode,
  IconWifi,
  IconToggleOff,
  IconCoffee,
  IconSpeedometer,
} from '../../../assets/svg';

export const featuresIconSwitcher = (param: FeaturesIconMode | null) => {
  switch (param) {
    case FeaturesIconMode.Bed:
      return <IllustrationKingSizeBed />;
    case FeaturesIconMode.Shower:
      return <IllustrationShower />;
    case FeaturesIconMode.Couch:
      return <IllustrationCouch />;
    case FeaturesIconMode.Window:
      return <IconKanban />;
    case FeaturesIconMode.Floor:
      return <IconBarcode />;
    case FeaturesIconMode.Wifi:
      return <IconWifi />;
    case FeaturesIconMode.Heat:
      return <IconToggleOff />;
    case FeaturesIconMode.Coffee:
      return <IconCoffee />;
    case FeaturesIconMode.Parking:
      return <IconSpeedometer />;
    default:
      return null;
  }
};

export const singleFeatureTemplate = (item: FeaturesListItemProps, tag: React.ElementType) => {
  const { key, iconMode, title } = item;

  return (
    <Styled.FeaturesListItem as={tag} {...{ key }}>
      {iconMode && (
        <Styled.FeaturesIcon as="span">{featuresIconSwitcher(iconMode)}</Styled.FeaturesIcon>
      )}
      <Styled.FeaturesTitle variant="textL">{title}</Styled.FeaturesTitle>
    </Styled.FeaturesListItem>
  );
};

export const settings: CarouselSettings = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  speed: 1000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
      },
    },
    {
      breakpoint: 10000,
      settings: 'unslick',
    },
  ],
  appendDots: (dots: React.ReactNode) => (
    <CarouselStyled.CarouselPaginationDots>{dots}</CarouselStyled.CarouselPaginationDots>
  ),
  customPaging: () => <CarouselStyled.CarouselPagination />,
};
